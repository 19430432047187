import React, { useContext, useState, useEffect } from 'react'
import { graphql } from "gatsby"
import Form from 'components/form'
import Link from 'components/link'
import { SUBMIT_FORM } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import { MainContext } from "../context/context"
import ContactBackground from '../images/contact-background.png'
import SEO from 'components/seo'

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                contactPage {
                    heading
                    address
                    phoneNumber
                    emailAddress
                    link {
                        title
                        url
                    }
                    pressEmail
                    pressPhoneNumber
                    researchEmail
                    researchPhoneNumber
                }
            }
            form(id: "Zm9ybTo0NDY") {
                ...FormFieldsFragment
            }
        }
    }
`

export default ({
    data
}) => {
    const {
        openModal,
        options: {
            copy = {}
        }
    } = useContext(MainContext)

    const {
        contactFormSuccessTitle,
        contactFormSuccessCopy
    } = copy

    const [submitForm, { loading, data: formData, error }] = useMutation(SUBMIT_FORM)
    const [isLoading, setIsLoading] = useState(false)
    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)
        },300)
    }, [])

    const {
        page: {
            contactPage: fields
        },
        form
    } = data.siteFields

    const handleSubmit = data => {
        const json = JSON.stringify(data)
        setIsLoading(true)

        submitForm({
            variables: {
                type: 'Contact',
                data: json
            },
        }).then(response => {
            setIsLoading(false)

            openModal({
                type: 'CONTACT_CONFIRM',
                data: {
                    title: contactFormSuccessTitle,
                    body: contactFormSuccessCopy
                }
            })
        }).catch(error => {
            console.log(error)

            setIsLoading(false)
        })
    }

    const {
        heading,
        address,
        phoneNumber,
        emailAddress,
        pressEmail,
        link,
        pressPhoneNumber,
        researchEmail,
        researchPhoneNumber
    } = fields

    return (
        <>
            <SEO title="Contact Us" />
            <div className={`contact-page container ${ hasMounted ? 'mounted' : '' }`}
                style={{
                    backgroundImage: `url(${ ContactBackground })`
                }}
            >
                <div className="grid">
                    <div className="column">
                        <h1 className="h2" dangerouslySetInnerHTML={{ __html: heading }} />
                        <div className="copy" dangerouslySetInnerHTML={{ __html: address }} />
                        <div>
                            { phoneNumber ?
                                <a className="phone" href={`tel:${ phoneNumber }`}>
                                    <i className="fa fa-phone" />
                                    <span>
                                        { phoneNumber }
                                    </span>
                                </a>
                            : null}
                        </div>
                        <div>
                            { emailAddress ?
                                <a className="email" href={`mailto:${ emailAddress }`}>
                                    <i className="fa fa-envelope" />
                                    <span>
                                        { emailAddress }
                                    </span>
                                </a>
                            : null}
                            { link ?
                                <Link
                                    to={link?.url}
                                    className={'button low'}
                                >
                                    {link?.title}
                                </Link>
                            : null}
                        </div>
                        <div className="grid">
                            { (pressEmail || pressPhoneNumber) &&
                                <div>
                                        <p className="bold">
                                            Press Enquiry
                                        </p>
                                        <p>
                                            { pressEmail }
                                        </p>
                                        <p>
                                            { pressPhoneNumber }
                                        </p>
                                </div>
                            }
                            { (researchEmail || researchPhoneNumber) &&
                                <div>
                                    <p className="bold">
                                        Research Enquiry
                                    </p>
                                    <p>
                                        { researchEmail }
                                    </p>
                                    <p>
                                        { researchPhoneNumber }
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="column">
                        <Form 
                            form={form} 
                            id="Zm9ybTo0NDY=" 
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}